<template>
  <v-row justify="center">
    <v-dialog v-model="dialogNotification" persistent max-width="60%">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("Applicant Email") }}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" class="mt-5">
            <v-container>
              <v-row>
                <v-col cols="12" lg="3" md="6" xs="12">
                  <span class="key"> {{ $t("Code") }}: </span>
                </v-col>
                <v-col cols="12" lg="3" md="6" xs="12" class="value">
                  {{ data.application_number }}
                </v-col>
                <v-col cols="12" lg="3" md="6" xs="12">
                  <span class="key"> {{ $t("Name") }}: </span>
                </v-col>
                <v-col cols="12" lg="3" md="6" xs="12" class="value">
                  {{ data.student_full_name }}
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="3" md="6" xs="12">
                  <span class="key"> {{ $t("Grade") }}: </span>
                </v-col>

                <v-col cols="12" lg="3" md="6" xs="12" class="value">
                  {{ data.student_grade_level }}
                </v-col>
              </v-row>
              <v-divider></v-divider>

              <!-- Sms -->
              <v-row>
                <v-col cols="12">
                  <label>{{ $t("SMS") }}</label>
                  <v-row>
                    <v-col cols="12" style="float: right; text-align: right">
                      <span style="font-size: 10px" v-if="!arabic"
                        >{{ $t("number of chars") }} :
                        {{ notification.smsMessage.length
                        }}{{ $t("chars / max :160") }}
                        {{
                          $t("character for one message/ number of messages")
                        }}: {{ numberMessage }}</span
                      >
                      <span style="font-size: 10px" v-else
                        >{{ $t("number of chars") }} :
                        {{ notification.smsMessage.length }}
                        {{ $t("chars / max :70") }}
                        {{
                          $t("character for one message / number of messages")
                        }}: {{ numberMessage }}</span
                      >
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-textarea
                        rows="4"
                        :placeholder="$t('Message')"
                        :rules="[
                          validationRules.required,
                          checkSMSTextValidation,
                        ]"
                        v-model="notification.smsMessage"
                        solo
                        @focus="onFoucs"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <br />
              <v-divider></v-divider>
              <!-- Email -->
              <v-row>
                <v-col cols="12" lg="3" md="3" xs="12">
                  <span class="key"> {{ $t("Subject") }}: </span>
                </v-col>
                <v-col cols="12" lg="9" md="9" xs="12" class="value">
                  <v-text-field
                    solo
                    v-model="notification.subject"
                    :rules="[validationRules.required]"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="3" md="3" xs="12">
                  <span class="key"> {{ $t("Email") }}: </span>
                </v-col>
                <v-col cols="12" lg="9" md="9" xs="12" class="value">
                  <vue-editor
                    @focus="onFoucs"
                    v-model="notification.message"
                  ></vue-editor>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions class="my-4">
              <v-spacer></v-spacer>
              <v-btn class="modal-btn-cancel" @click="closeDialog">{{
                $t("Close")
              }}</v-btn>
              <v-btn
                class="modal-btn-save"
                @click="changeExamResult"
                :loading="loading"
                :disabled="loading"
                >{{ $t("Send") }}</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snack"
      :timeout="snackTime"
      :color="snackColor"
      :right="true"
      :top="true"
    >
      <!-- {{ snackText }} -->

      <span class="white--text">
        {{ snackText }}
      </span>

      <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
    </v-snackbar>
  </v-row>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { VueEditor } from "vue2-editor";
import { validationMixin } from "../../mixins/validationMixin";
export default {
  props: ["data", "dialogNotification"],
  mixins: [validationMixin],
  components: {
    VueEditor,
  },
  data() {
    return {
      snack: false,
      snackColor: "",
      snackText: "",
      snackTime: 7000,
      submitted: false,
      loading: false,
      notification: {
        message: "",
        subject: "",
        smsMessage: "",
      },
      numberMessage: 0,
      arabic: false,
      valid: true,
      validation_errors: [],
    };
  },

  computed: {
    ...mapGetters(["getApiUrl"]),
  },
  methods: {
    checkSMSTextValidation() {
      if (
        this.notification.smsMessage != null &&
        this.notification.smsMessage != undefined &&
        this.notification.smsMessage != ""
      ) {
        let format = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
        // error = /\d/.test(val) ? false : true;
        // if (!error) {
        //   return "Please write only arabic letters.";
        // }
        for (var i = 0; i < this.notification.smsMessage.length; i++) {
          // console.log(val.charAt(i));
          var unicode = this.notification.smsMessage.charCodeAt(i);

          if (unicode != 8 && !format.test(this.notification.smsMessage[i])) {
            //if the key isn't the backspace key (which we should allow)
            if (unicode != 32) {
              if (
                (unicode < 48 || unicode > 57) &&
                (unicode < 0x0600 || unicode > 0x06ff)
              ) {
                //if not a number or arabic
                this.arabic = false;
              } else {
                this.arabic = true; //disable key press
              }
            }
          }
        }
        if (this.arabic) {
          this.numberMessage =
            this.notification.smsMessage.length > 70
              ? Math.ceil(this.notification.smsMessage.length / 70)
              : 1;
        } else {
          this.numberMessage =
            this.notification.smsMessage.length > 160
              ? Math.ceil(this.notification.smsMessage.length / 160)
              : 1;
        }
      }
    },
    changeExamResult() {
      if (!this.valid) {
        this.$refs.form.validate();
      } else {
        this.loading = true;
        axios
          .post(
            this.getApiUrl +
              "/applicant/SendbroadCastApplicant/" +
              this.data.id,
            this.notification,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            this.loading = false;
            if (response.data.status.error) {
              this.snack = true;
              this.snackColor = "red";
              this.snackText = response.data.status.message;
              this.validation_errors = response.data.status.validation_errors;
            } else {
              this.snack = true;
              this.snackColor = "green";
              this.snackText = response.data.status.message;
              window.location.reload();
              // this.closeDialog();
            }
          });
      }
    },
    closeDialog() {
      this.notification = {
        message: "",
        subject: "",
        smsMessage: "",
      };
      this.dialogNotification = false;
      this.$emit("childToParent", false, false);
      this.$v.$reset();

      this.submitted = true;
      this.validation_errors = [];
    },
    onFoucs() {
      this.validation_errors = [];
      // this.$refs.form.resetValidation();
    },
  },
};
</script>
<style lang="css" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";

.hidden-input {
  display: none;
}
.hidden {
  display: none !important;
}
.v-application .error {
  background-color: white !important;
}
.headline {
  word-break: break-word !important;
}

/* .key {
  background: #e0e0e0 !important;
  font-size: 14px !important;
  height: 20px;
  padding: 10px 10px;
  border-radius: 4px;
  font-size: 14px !important;
  line-height: 2.2;
  margin: 2%;
} */
@media (max-width: 350px) {
  .headline {
    font-size: 1.3rem !important;
  }
  .key {
    font-size: 12px !important;
  }
  .value {
    font-size: 12px !important;
  }
  .theme--light.v-select .v-select__selection--comma {
    font-size: 12px !important;
  }
}
</style>
