<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="60%">
      <v-card>
        <v-card-title>
          <span class="headline py-3">{{ $t("Admission Exam Result") }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" lg="3" md="6" xs="12">
                <span class="key"> {{ $t("Code") }}: </span>
              </v-col>
              <v-col cols="12" lg="3" md="6" xs="12" class="value">
                {{ data.application_number }}
              </v-col>
              <v-col cols="12" lg="3" md="6" xs="12">
                <span class="key"> {{ $t("Name") }} : </span>
              </v-col>
              <v-col cols="12" lg="3" md="6" xs="12" class="value">
                {{ data.student_full_name }}
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" lg="3" md="6" xs="12">
                <span class="key"> {{ $t("Grade") }} : </span>
              </v-col>

              <v-col cols="12" lg="3" md="6" xs="12" class="value">
                {{ data.student_grade_level }}
              </v-col>

              <v-col cols="12" lg="3" md="6" xs="12"
                ><span class="key"> {{ $t("Nationality") }} : </span>
              </v-col>
              <v-col cols="12" lg="3" md="6" xs="12" class="value">
                {{ data.student_nationality }}
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                lg="3"
                md="6"
                xs="12"
                v-if="data.acceptance_age_reason != null"
              >
                <span class="key"> {{ $t("Age Exception") }} : </span>
              </v-col>
              <v-col cols="12" lg="3" md="6" xs="12" class="value">
                {{ data.acceptance_age_reason }}
              </v-col>
              <v-col
                cols="12"
                lg="3"
                md="6"
                xs="12"
                v-if="data.iqama_reason != null"
              >
                <span class="key"> {{ $t("Iqama Exception") }}: </span>
              </v-col>
              <v-col cols="12" lg="3" md="6" xs="12" class="value">
                {{ data.iqama_reason }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" lg="3" md="6" xs="12">
                <label>{{ $t("Exam Result") }}</label>
                <v-select
                  :items="[
                    { text: $t('Select Result'), value: '' },
                    { text: $t('Passed'), value: 'pass' },
                    {
                      text: $t('Passed With Probations'),
                      value: 'passed_with_probations'
                    },
                    { text: $t('Failed'), value: 'failed' }
                  ]"
                  v-model.trim="$v.exam_result.exam_result.$model"
                  solo
                  required
                  @focus="onFocus()"
                ></v-select>
                <p
                  class="error--text"
                  v-if="
                    $v.exam_result.exam_result.$error &&
                      !$v.exam_result.exam_result.required
                  "
                >
                  {{ $t("This field is required") }}
                </p>
                <div v-if="validation_errors">
                  <p
                    class="error--text"
                    v-for="(error, index) in validation_errors.exam_result"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
              <v-col cols="12" md="6" xs="12">
                <label>{{ $t("Exam Notes") }}</label>
                <v-textarea
                  solo
                  v-model.trim="$v.exam_result.exam_result_notes.$model"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="modal-btn-cancel" @click="closeDialog">{{
              $t("Close")
            }}</v-btn>
            <v-btn
              class="modal-btn-save"
              @click="changeExamResult"
              :loading="disabled"
              :disabled="disabled"
              >{{ $t("Save") }}</v-btn
            >
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snack"
      :timeout="snackTime"
      :color="snackColor"
      :right="true"
      :top="true"
    >
      <!-- {{ snackText }} -->

      <span class="white--text">
        {{ snackText }}
      </span>

      <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
    </v-snackbar>
  </v-row>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
  props: ["data", "dialog"],
  data() {
    return {
      snack: false,
      snackColor: "",
      snackText: "",
      snackTime: 7000,
      submitted: false,
      disabled: false,
      exam_result: {
        exam_result: "",
        exam_result_notes: ""
      },
      validation_errors: []
    };
  },
  validations: {
    exam_result: {
      exam_result: { required },
      exam_result_notes: { required }
    }
  },
  computed: {
    ...mapGetters(["getApiUrl"])
  },
  methods: {
    changeExamResult() {
      if (this.$v.exam_result.exam_result.$error == false) {
        this.disabled = true;
        axios
          .post(
            this.getApiUrl + "/applicant/changeExamResult/" + this.data.id,
            this.exam_result,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token
              }
            }
          )
          .then(response => {
            if (response.data.status.error == false) {
              this.dialog = false;
              this.exam_result = {
                exam_result: "",
                exam_result_notes: ""
              };
              this.validation_errors = [];
              this.$v.$reset();
              this.disabled = false;

              this.snack = true;
              this.snackColor = "success";
              this.snackText = "You have added exam result to this applicant";
              this.submitted = true;
              // const index = this.data.indexOf(this.data.id);

              // this.data.splice(index, 1);
              this.$emit("childToParent", false, false);

              // this.validation_errors = [];
            } else {
              // this.snack = true;
              // this.snackColor = "error";
              // this.snackText = response.data.status.message;
              this.disabled = false;
              this.validation_errors = response.data.status.validation_errors;
            }
          });
        // .catch(err => {
        //   console.log(err);
        // });
      }
    },
    closeDialog() {
      this.disabled = false;
      this.dialog = false;
      this.$emit("childToParent", false, false);
      this.$v.$reset();
      this.exam_result = {
        exam_result: "",
        exam_result_notes: ""
      };
      this.submitted = true;
      this.validation_errors = [];
    },
    onFocus() {
      this.validation_errors = [];
    }
  }
};
</script>
<style lang="css" scoped>
@import "../styles/_variables.scss";
@import "../styles/_modals.scss";

.hidden-input {
  display: none;
}
.hidden {
  display: none !important;
}
.v-application .error {
  background-color: white !important;
}
.headline {
  word-break: break-word !important;
}

/* .key {
  background: #e0e0e0 !important;
  font-size: 14px !important;
  height: 20px;
  padding: 10px 10px;
  border-radius: 4px;
  font-size: 14px !important;
  line-height: 2.2;
  margin: 2%;
} */
@media (max-width: 350px) {
  .headline {
    font-size: 1.3rem !important;
  }
  .key {
    font-size: 12px !important;
  }
  .value {
    font-size: 12px !important;
  }
  .theme--light.v-select .v-select__selection--comma {
    font-size: 12px !important;
  }
}
</style>
